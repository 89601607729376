import React from 'react';

interface IDivider {
  color?: string;
  marginY?: string;
}

const Divider = ({ color = 'border-gray-400', marginY = 'my-4' }: IDivider) => {
  return <hr className={`border-t-[1px] w-full ${color} ${marginY}`} />;
};

export default Divider;
